import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "../../../util"
import { VeraImage } from "../../elements/vera-image"

const Article = ({ title, image, meta, slug, _type }) => {
  const video = () => {
    if (!image.video) {
      return ""
    }
    const id = image.video.split("=")[1]
    return `https://img.youtube.com/vi/${id}/hqdefault.jpg`
  }
  return (
    <Link to={linkResolver({ _type, slug: slug.current })}>
      <div className="article-teaser__image-container">
        {image && !image.video && (
          <>
            {image && image.asset && (
              <>
                <VeraImage
                  imgStyle={{ borderRadius: 20 }}
                  asset={image.asset}
                  className="article-teaser__image"
                />
                <span className="article-teaser__image-overlay">
                  {meta &&
                    meta.article_type_ref &&
                    meta.article_type_ref.article_type_title}
                </span>
              </>
            )}
            {image && image.image && (
              <>
                <VeraImage
                  asset={image.image.asset}
                  imgStyle={{ borderRadius: 20 }}
                  className="article-teaser__image"
                />
                <span className="article-teaser__image-overlay">
                  {meta &&
                    meta.article_type_ref &&
                    meta.article_type_ref.article_type_title}
                </span>
              </>
            )}
          </>
        )}
        {image && image.video && (
          <>
            <img
              style={{ width: "100%" }}
              className="article-teaser__image"
              src={video()}
            />
            <span
              className="article-teaser__image-overlay"
              style={{ bottom: 4 }}
            >
              {meta &&
                meta.article_type_ref &&
                meta.article_type_ref.article_type_title}
            </span>
          </>
        )}
      </div>
      <h3 className="article-teaser__header">{title}</h3>
    </Link>
  )
}

export default Article
