import React from "react"

const SectionColumns = ({
  children,
  isMobile,
  isDesktop,
  isMultiline,
  isGapless,
  sectionClasses,
  isCentered,
  wide,
  id,
}) => {
  const Container = ({ children }) => (
    <div className="container">{children}</div>
  )

  const content = (
    <div
      className={`columns ${isCentered ? "is-centered" : ""} ${
        isDesktop ? "is-desktop" : ""
      } ${isMobile ? "is-mobile" : ""} ${isMultiline ? "is-multiline" : ""} ${
        isGapless ? "is-gapless" : ""
      }`}
    >
      {children}
    </div>
  )

  return (
    <section
      id={id}
      className={`section ${sectionClasses ? sectionClasses : ""}`}
    >
      {wide ? <>{content}</> : <Container>{content}</Container>}
    </section>
  )
}

export default SectionColumns
