import React from "react"
import PageTitle from "../dynamic-components/page-title"
import SectionColumns from "../elements/section-columns"
import HorizontalScrollable from "../hoc/horizontal-scrollable"
import AnchorLink from "react-anchor-link-smooth-scroll"
import BlockContent from "@sanity/block-content-to-react"
import SectionCta from "../elements/section-cta"
import Article from "../dynamic-components/article/article"
import { VeraImage } from "../elements/vera-image"

const AboutComponents = ({ components }) => {
  const menuItems = components.article_loop.map(item => ({
    title: item.section_nav_title,
    href: `#${item.section_nav_title.replace(/\s+/g, "-").toLowerCase()}`,
  }))

  menuItems.push({
    title: components.article_teaser_nav_title,
    href: `#${components.article_teaser_nav_title
      .replace(/\s+/g, "-")
      .toLowerCase()}`,
  })

  const articleSection =
    components.article_teaser.article_teaser_references.length === 3 ? (
      <div className="columns has-wide-margin">
        {components.article_teaser.article_teaser_references.map(
          (article, i) => (
            <div
              key={`${i}_${article._id}`}
              className="column is-one-third article-teaser__article"
            >
              <Article {...article} />
            </div>
          )
        )}
      </div>
    ) : null

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10-tablet is-offset-1-tablet is-half-desktop is-offset-3-desktop">
              <h1 className="title title--large has-text-centered">
                {components.about_intro.page_title_header}
              </h1>
              <p className="sub-header has-text-centered">
                {components.about_intro.page_title_intro}
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-10-tablet is-offset-1-tablet is-half-desktop is-offset-3-desktop">
              <div className="about-page__menu">
                <HorizontalScrollable
                  className="product-page__menu"
                  childClassName="product-page__menu-item"
                >
                  {menuItems.map(item => {
                    return (
                      <AnchorLink
                        offset="100"
                        className="anchor-link"
                        key={item.href}
                        href={item.href}
                      >
                        {item.title}
                      </AnchorLink>
                    )
                  })}
                </HorizontalScrollable>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SectionColumns sectionClasses="has-padding-top-0">
        <div className="column is-10-tablet is-offset-1-tablet is-half-desktop is-offset-3-desktop rich-text">
          {components.article_loop.map((item, i) => (
            <div
              key={`${item.section_nav_title
                .replace(/\s+/g, "-")
                .toLowerCase()}__${i}`}
            >
              <h2
                className="h4"
                id={item.section_nav_title.replace(/\s+/g, "-").toLowerCase()}
              >
                {item.section_title}
              </h2>
              <BlockContent
                blocks={item._rawSectionContent}
                projectId="2g2aoezl"
                dataset="vera-com"
              />
            </div>
          ))}
        </div>
      </SectionColumns>
      <section
        className="section has-background-light"
        id={components.article_teaser_nav_title
          .replace(/\s+/g, "-")
          .toLowerCase()}
      >
        <div className="container">
          {components.article_teaser.cta_section &&
            components.article_teaser.cta_section.section_cta_cta &&
            components.article_teaser.cta_section.section_cta_cta.cta_link &&
            components.article_teaser.cta_section.section_cta_cta.cta_text &&
            components.article_teaser.cta_section.section_cta_paragraph &&
            components.article_teaser.cta_section.section_cta_title && (
              <div className="columns">
                <div className="column is-10-tablet is-offset-1-tablet is-half-desktop is-offset-3-desktop has-text-centered">
                  <SectionCta
                    section_cta_cta={
                      components.article_teaser.cta_section.section_cta_cta
                    }
                    section_cta_title={
                      components.article_teaser.cta_section.section_cta_title
                    }
                    section_cta_paragraph={
                      components.article_teaser.cta_section
                        .section_cta_paragraph
                    }
                  />
                </div>
              </div>
            )}

          {articleSection}
        </div>
      </section>

      {/* <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10-tablet is-offset-1-tablet is-half-desktop is-offset-3-desktop has-text-centered">
              <h2 className="h3">{components.team_intro.page_title_header}</h2>
              <p className="sub-header">
                {components.team_intro.page_title_intro}
              </p>
            </div>
          </div>
          <div className="columns has-wide-margin is-multiline">
            {components.team_members.map((member, i) => (
              <div key={`${i}_${member._id}`} className="column is-one-third">
                <VeraImage asset={member.Image.asset} />
                <div className="about-page__team-info-box-header">
                  <p className="about-page__member-name">{member.Name}</p>
                </div>
                <div className="about-page__team-info-box-text about-page__member-info">
                  <BlockContent blocks={member.description} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </>
  )
}

export default AboutComponents
