import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import PageTitle from "../components/dynamic-components/page-title"
import AboutComponents from "../components/content-pages/about-components"
import SEO from "../components/seo"

const AboutPage = ({ data, location }) => {
  const { about_page } = data.sanitySiteInfo

  return (
    <Layout>
      <SEO title="About Vera Mexicana" url={location.pathname} />
      <AboutComponents components={about_page} />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    sanitySiteInfo(site_id: { current: { eq: "veracom" } }) {
      about_page {
        article_teaser_nav_title
        about_intro {
          page_title_header
          page_title_intro
        }
        article_loop {
          ... on SanityArticleSectionWNav {
            _rawSectionContent
            _type
            _key
            section_nav_title
            section_title
          }
        }
        article_teaser {
          _key
          _type
          article_teaser_references {
            ... on SanityArticle {
              _id
              slug {
                current
              }
              title: article_title
              meta: article_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              _type
              image: article_image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityRecipe {
              _id
              slug {
                current
              }
              _type
              title: recipe_title
              meta: recipe_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              image: recipe_image_image {
                video
                image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
          cta_section {
            section_cta_cta {
              cta_link {
                ... on SanityArticle {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityArticleType {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityCollection {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityDynamicPage {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityProduct {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityRecipe {
                  slug {
                    current
                  }
                  _type
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
          title
        }
        team_intro {
          page_title_header
          page_title_intro
        }
        team_members {
          Name
          Image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          role
          description {
            _rawChildren
            _key
            _type
            children {
              _key
              _type
              marks
              text
            }
            list
            style
          }
        }
      }
    }
  }
`
