import React from "react"
import AnchorButton from "./anchor-button"
import { linkResolver } from "../../util"

const SectionCta = ({
  section_cta_cta,
  section_cta_paragraph,
  section_cta_title,
}) => {
  if (!section_cta_cta) {
    return null
  }
  const { cta_link, cta_text } = section_cta_cta

  return (
    <>
      <h2 className="h3">{section_cta_title}</h2>
      <p className="sub-header">{section_cta_paragraph}</p>
      {cta_link && cta_text && (
        <span className="cta-wrapper">
          <AnchorButton
            to={linkResolver({
              _type: cta_link._type,
              slug: cta_link.slug.current,
            })}
          >
            {cta_text}
          </AnchorButton>
        </span>
      )}
    </>
  )
}

export default SectionCta
